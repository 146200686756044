















































import Vue from "vue";
import SignupButton from "@/components/SignupButton.vue";
import PreFooter from "@/components/PreFooter.vue";
import TwitterTestimonials from "@/components/TwitterTestimonials.vue";
import api from "@/api/api";
import { IPlan } from "@/types";

export default Vue.extend({
  components: {
    PreFooter,
    TwitterTestimonials,
    SignupButton,
  },
  data() {
    return {
      plans: [] as IPlan[],
    };
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
  async mounted() {
    this.plans = await api.getMembersipPlans();
  },
});
